import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Launch from "@spectrum-icons/workflow/Launch";
import Money from "@spectrum-icons/workflow/Money";
import User from "@spectrum-icons/workflow/User";
import { Flex, Link } from "@adobe/react-spectrum";
// import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import parse from "html-react-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import SupportCard from "../components/SupportCard";

import SEO from "../components/seo";
import { TabsContext } from "../contexts/TabsContext";
import SupportJSON from "../data/support.json";

// import "../styles/accordion.css";
// import "react-accessible-accordion/dist/fancy-example.css";

const SupportContainer = styled.div`
  max-width: 900px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* background-color: gray; */
`;

const StyledAccordion = styled(Accordion)`
  border: 1px solid var(--spectrum-global-color-gray-400);
  border-radius: 8px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 25px;
  margin-bottom: 15px;

  @media (max-width: 900px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .accordion__item + .accordion__item {
    border-top: 1px solid var(--spectrum-global-color-gray-400);
  }

  .accordion__item:first-of-type .accordion__button {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .accordion__item:last-of-type .accordion__button {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .accordion__button {
    background-color: var(--spectrum-global-color-gray-200);
    color: var(--spectrum-global-color-gray-800);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-weight: var(--spectrum-global-font-weight-bold);
  }

  .accordion__button:hover {
    background-color: var(--spectrum-global-color-gray-75);
  }

  .accordion__button:before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    color: ${props =>
      /* console.log("PROPS", props); */
      `var(${props.accentcolor})`};
    /* color: var(--spectrum-global-color-yellow-400); */
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const SupportPage = () => {
  const breakpoints = useBreakpoint();
  const { activeTab, setActiveTab } = useContext(TabsContext);
  useEffect(() => {
    if (activeTab !== "support") {
      setActiveTab("support");
    }
  });

  const [activeAccordion, setActiveAccordion] = useState();

  const COMMERCIAL_COLOR = "--spectrum-global-color-yellow-400";
  const TECHNICAL_COLOR = "--spectrum-global-color-purple-400";
  const CONTACT_COLOR = "--spectrum-global-color-celery-400";

  const buttonHandler = target => {
    setActiveAccordion(target);
    if (breakpoints.phoneOnly === true) navigate("#accordion");
  };

  const techSettings = {
    title: "FAQ",
    subtitle: "Tech Stuff",
    description:
      "Installation, compatibility, functionality, usage and whatnot",
    Icon: Launch,
    color: `var(${TECHNICAL_COLOR})`,
    cta: {
      buttonText: "View",
      buttonHandler,
      buttonTarget: "technical",
    },
  };

  const commercialSettings = {
    title: "FAQ",
    subtitle: "Commercial",
    description:
      "How to buy, accepted payments, licensing, refunds, privacy, etc.",
    Icon: Money,
    color: `var(${COMMERCIAL_COLOR})`,
    cta: {
      buttonText: "View",
      buttonHandler,
      buttonTarget: "commercial",
    },
  };

  const contactSettings = {
    title: "Q&A",
    subtitle: "CONTACTS",
    description: "Get in touch, ask questions, or share your thoughts",
    Icon: User,
    color: `var(${CONTACT_COLOR})`,
    cta: {
      buttonText: "Write",
      buttonHandler,
      buttonTarget: "contact",
    },
  };

  const parserOptions = {
    htmlparser2: {
      lowerCaseTags: false,
    },
    // eslint-disable-next-line consistent-return
    replace: node => {
      if (node.name === "a") {
        // console.log("<a> node", node);
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link>
            <a href={node.attribs.href} rel="noreferrer" target="_blank">
              {node.children[0].data}
            </a>
          </Link>
        );
      }
    },
  };

  const showAccordion = subject => {
    if (subject !== "commercial" && subject !== "technical") return null;
    return (
      <StyledAccordion
        allowZeroExpanded
        accentcolor={
          subject === "commercial" ? COMMERCIAL_COLOR : TECHNICAL_COLOR
        }
      >
        {SupportJSON[subject].map(({ q, a, id }) => (
          <AccordionItem key={id}>
            <AccordionItemHeading>
              <AccordionItemButton>{q}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>{parse(a, parserOptions)}</p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </StyledAccordion>
    );
  };

  return (
    <>
      <SEO title="Support" description="FAQ and Contacts" slug="support" />
      <Flex
        direction="row"
        justifyContent="center"
        gap="size-100"
        wrap
        maxWidth="900px"
        marginTop="size-300"
        UNSAFE_style={{ margin: `0 auto` }}
      >
        <SupportCard {...commercialSettings} />
        <SupportCard {...techSettings} />
        <SupportCard {...contactSettings} />
      </Flex>
      <SupportContainer id="accordion">
        {showAccordion(activeAccordion)}
      </SupportContainer>
    </>
  );
};

export default SupportPage;
