import { Button, Flex, View } from "@adobe/react-spectrum";
import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";

const SupportCardWrapper = styled.div`
  max-width: 290px;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin: 20px 4px 20px 4px;
  @media (min-width: 600px) {
    margin: 2px;
  }

  .icon-container {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 75px;
    height: 75px;
    background-color: ${({ color }) =>
      color || "var(--spectrum-global-color-orange-400)"};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: var(--spectrum-global-color-gray-75);
      margin: auto;
    }
  }
`;

const SupportCard = ({ Icon, title, subtitle, description, color, cta }) => (
  // const SupportCard = props => (
  <SupportCardWrapper color={color}>
    <View
      borderWidth="thin"
      borderColor="dark"
      borderRadius="medium"
      backgroundColor="gray-75"
      position="relative"
      height="100%"
    >
      <Flex direction="column" justifyContent="space-between" height="100%">
        <div
          style={{
            width: `100%`,
            textAlign: `right`,
            paddingLeft: `30px`,
            paddingRight: `30px`,
          }}
        >
          <div className="icon-container">
            <Icon size="XL" />
          </div>
          <h1
            style={{
              fontWeight: `700`,
              fontSize: `40px`,
              marginBottom: `0`,
              marginTop: `20px`,
            }}
          >
            {title}
          </h1>
          <h2
            style={{
              fontWeight: `300`,
              marginTop: `-2px`,
              textTransform: `uppercase`,
              color,
            }}
          >
            {subtitle}
          </h2>
          <p>{description}</p>
        </div>
        <div
          style={{
            backgroundColor: `var(--spectrum-global-color-gray-200)`,
            borderBottomLeftRadius: `7px`,
            borderBottomRightRadius: `7px`,
            borderTop: `1px solid var(--spectrum-global-color-gray-400)`,
            paddingTop: `15px`,
            paddingBottom: `15px`,
            paddingLeft: `30px`,
            paddingRight: `30px`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          {subtitle === "CONTACTS" ? (
            <ContactForm buttonText={cta.buttonText} />
          ) : (
            <Button
              type="button"
              variant="cta"
              onPress={() => cta.buttonHandler(cta.buttonTarget)}
            >
              {cta.buttonText}
            </Button>
          )}
        </div>
      </Flex>
    </View>
  </SupportCardWrapper>
);

export default SupportCard;
