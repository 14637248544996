import React, { useState, useEffect } from "react";
import {
  Form,
  TextField,
  Button,
  TextArea,
  DialogTrigger,
  Dialog,
  Heading,
  Flex,
  Divider,
  Content,
  ButtonGroup,
  Text,
  ProgressCircle,
} from "@adobe/react-spectrum";
import Email from "@spectrum-icons/workflow/Email";
import Send from "@spectrum-icons/workflow/Send";
import useEmail from "../utils/useEmail";

// Netlify serverless functions with Gatsby
// https://www.joshwcomeau.com/gatsby/using-netlify-functions-with-gatsby/

const ContactForm = ({ buttonText }) => {
  // Email + message
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  // Validation
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [isMessageValid, setIsMessageValid] = useState(undefined);
  //
  const { isWaiting, error, returnMessage, send } = useEmail({
    email,
    message,
    // subject is the honeypot
    subject,
  });

  // EMAIL stuff
  const onEmailBlur = () => {
    setIsEmailValid(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ? "valid"
        : "invalid"
    );
  };
  const onEmailChange = newVal => {
    setEmail(newVal);
    setIsEmailValid(undefined);
  };

  // MESSAGE stuff
  const onMessageBlur = () => {
    setIsMessageValid(message === "" ? "invalid" : "valid");
  };
  const onMessageChange = newVal => {
    setMessage(newVal);
    onMessageBlur();
  };

  useEffect(() => {
    if (returnMessage === "" && error === false) return;
    if (error) {
      alert(returnMessage);
    } else {
      alert(returnMessage);
    }
  }, [returnMessage]);

  const submitForm = async close => {
    // console.log("returnMessage BEFORE SENDING", returnMessage);
    // console.log("isWaiting BEFORE SENDING", isWaiting);
    await send();
    close();
    setEmail("");
    setMessage("");
    setSubject(""); // honeypot
    setIsEmailValid(undefined);
    setIsMessageValid(undefined);
    // console.log("returnMessage AFTER SENDING", returnMessage);
    // console.log("isWaiting AFTER SENDING", isWaiting);
  };

  return (
    <DialogTrigger>
      <Button type="button" variant="cta">
        {buttonText}
      </Button>
      {close => (
        <Dialog>
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <Email size="S" />
              <Text>Get in touch</Text>
              {/* <p>{`isWaiting: ${isWaiting}, returnMessage: ${returnMessage}`}</p> */}
              {/* <p>{`subject: ${subject}`}</p> */}
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <Form
              isDisabled={isWaiting}
              // onSubmit={submitForm}
              // maxWidth="size-3600"
              // margin="size-300"
              height="size-4000"
              // isRequired
              necessityIndicator="label"
              aria-labelledby="contact-form"
              labelPosition="top"
              labelAlign="start"
              UNSAFE_style={{ boxSizing: `content-box` }}
              alignItems="space-around"
            >
              <TextField
                isRequired
                name="email"
                label="Email"
                validationState={isEmailValid}
                placeholder="Enter your email"
                value={email}
                onChange={onEmailChange}
                onBlur={onEmailBlur}
              />

              <TextField
                name="subject"
                label="Subject"
                placeholder="I write you because..."
                width="100%"
                value={subject}
                onChange={setSubject}
                UNSAFE_style={{ display: `none` }}
              />

              <TextArea
                isRequired
                name="message"
                label="Message"
                placeholder="Dear Davide,"
                width="100%"
                minWidth="100%"
                height="size-2000"
                maxHeight="size-2000"
                value={message}
                onChange={onMessageChange}
                onBlur={onMessageBlur}
                validationState={isMessageValid}
              />

              <Flex justifyContent="space-between" alignItems="end">
                {isWaiting ? (
                  <ProgressCircle aria-label="Loading…" isIndeterminate />
                ) : (
                  <p>&nbsp;</p>
                )}
                <ButtonGroup marginTop="size-350" alignSelf="flex-end">
                  <Button variant="secondary" onPress={close}>
                    Cancel
                  </Button>
                  <Button
                    variant="cta"
                    isDisabled={
                      !(isMessageValid === "valid" && isEmailValid === "valid")
                    }
                    onPress={() => submitForm(close)}
                  >
                    <Send size="S" />
                    <Text>Send</Text>
                  </Button>
                </ButtonGroup>
              </Flex>
            </Form>
          </Content>
        </Dialog>
      )}
    </DialogTrigger>
  );
};

export default ContactForm;
