import { useEffect, useState } from "react";

// function sleep(milliseconds) {
//   const date = Date.now();
//   let currentDate = null;
//   do {
//     currentDate = Date.now();
//   } while (currentDate - date < milliseconds);
// }

export default function useEmail({ email, message, subject }) {
  // console.log("Email", email);
  // console.log("Message", message);

  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState(false);
  const [returnMessage, setReturnMessage] = useState("");

  useEffect(() => {
    setReturnMessage("");
    setError(false);
  });

  const send = async () => {
    if (subject !== "") {
      setReturnMessage("There's been a problem. Try again later");
      return;
    }
    setIsWaiting(true);
    const result = await fetch("/.netlify/functions/send-contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        message,
      }),
    });
    // Something wrong
    // console.log("STATUS: ", result.status);
    if (result.status >= 400 && result.status < 600) {
      // console.log("Something's Wrong");
      const jsonText = await result.json();
      // console.log("result.json()", jsonText);
      // {message: "Key not found", code: "unauthorized" }
      setIsWaiting(false);
      setReturnMessage("There's been a problem. Try again later");
    } else {
      // console.log("ALL GOOD", result);
      const jsonText = await result.json();
      // console.log("result.json()", jsonText);
      setIsWaiting(false);
      setReturnMessage("Thank you for getting in touch");
    }
  };

  return {
    isWaiting,
    error,
    returnMessage,
    send,
  };
}
